import React from "react";
import tw, { styled } from "twin.macro";
import { H4, P } from "../../utils/helpers";
import { ReactSVG } from "react-svg";

const FeatureContainer = tw.div`
  flex relative z-20 items-center flex-1
`;

const FeatureIcon = styled(ReactSVG)`
  ${tw`flex-none p-3`}
  ${(props) => props.white && `& path {fill: white;}`}
`;

const FeatureBody = styled.div`
  ${tw`flex-auto p-2`}
  ${(props) => (props.white ? tw`text-white` : tw`text-ink`)}
`;

const Feature = ({ icon, feature, description, white }) => (
  <FeatureContainer>
    <FeatureIcon src={icon.url} white={white} />
    <FeatureBody white={white}>
      <H4>{feature}</H4>
      <P tw="font-light">{description}</P>
    </FeatureBody>
  </FeatureContainer>
);

export default Feature;

import React from "react";
import { RichText } from "prismic-reactjs";
import tw from "twin.macro";
import Feature from "./Feature";

const FeaturesWrapper = tw.section`
  relative z-20
`;

const Features = tw.div`
  flex flex-col p-4 sm:flex-row md:p-6 lg:p-12 mx-auto max-w-7xl
`;

const FeatureStrip = ({ slice, white }) => (
  <FeaturesWrapper>
    <Features>
      {slice.fields &&
        slice.fields.map(
          ({ feature_icon, feature_title, feature_description }, idx) => (
            <Feature
              icon={feature_icon}
              feature={
                typeof feature_title === "object"
                  ? RichText.asText(feature_title)
                  : feature_title
              }
              description={
                typeof feature_description === "object"
                  ? RichText.asText(feature_description)
                  : feature_description
              }
              key={`feature-${idx}`}
              white={white ? true : false}
            />
          )
        )}
    </Features>
  </FeaturesWrapper>
);

export default FeatureStrip;
